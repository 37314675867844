import React, { useContext } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import BlockFour from "../components/BlockFour"
import {
  Link,
  useTranslation,
  I18nextContext,
} from "gatsby-plugin-react-i18next"
import YouTubePlayer from "../components/Youtubeplayer"
import PideCita from "../components/PideCita"

const Gracias = () => {
  const { t } = useTranslation()
  const { language } = useContext(I18nextContext)
  return (
    <Layout>
      <Seo lang={language} title={t("PIDE CITA")} />
      <PideCita
        phrase={t("EL LUGAR PARA CAMBIAR DE IMAGEN")}
        id="my-cool-header"
      />
      <div className="z-50 block md:hidden sticky top-12 shadow-lg bg-secondary">
        <Link to="/pidecita#pidecita">
          <button className="sticky top-0 bg-red-600 hover:bg-red-500 h-20 text-3xl shadow-md text-gray-900 min-w-full font-oswald uppercase m-0 transition delay-75 duration-300 ease-in-out">
            {t("PIDE CITA")}
          </button>
        </Link>
      </div>
      <div className="grid4" id="gracias">
        <div className="col-span-4 md:col-span-2 p-5 lg:p-10 xl:p-16 bg-gray-50 text-center">
          <YouTubePlayer url="https://youtu.be/YDUJr1K8w9o" />
        </div>
        <div className="col-span-4 md:col-span-2 p-5 lg:p-10 xl:p-16">
          <h2 className="pb-5 header2">
            <span className="text-red-700">{t("GRACIAS")}</span>
          </h2>
          <p className="text-gray-700">{t("GRACIAS TEXT")}</p>
        </div>
      </div>
      <BlockFour />
    </Layout>
  )
}

export default Gracias

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
